import React from "react";
import { justCollabIcon, rightArrowIcon } from "../../icons";
import './styles.css';

export const HomeComponent = () => {

    return (
        <div className="homepage-container">
            <div className="homepage-topbar">
                <div className="get-touch-container">
                    <a href="mailto:hello@watevr.in">Get in touch</a>
                </div>

                <div className="login-link-container">
                    <a href="https://login.justcollab.in/">Login</a>
                </div>

            </div>

            <div className="main-home-container">
                <div className="greeting-creator">Hey Creator 👋🏻</div>

                <div className="homepage-header1">
                    Collaborations, Made Simple
                </div>

                <a href="https://forms.gle/dqS68kHcKRX81bHU8" className="waitlist-button">
                    <div className="waitlist-button-text">
                        Join the wait list
                    </div>
                    <img className="right-arrow-icon" src={rightArrowIcon} />
                </a>
            </div>

            <div className="bottom-floater-set">

                <img className='just-collab-icon' src={justCollabIcon} />
                <div className="link-display-subtext">Powered by Watevr</div>
                <div className="home-spacer"></div>
            </div>
        </div>
    );

}

export default HomeComponent;