import { TextareaAutosize } from '@material-ui/core';
import React from 'react';
import './baseComponentStyles.css'

export const StyledButton = (props: any) => {

    const { buttonClass, buttonText, handlerFunction } = props;

    return <button className={'base-styled-button ' + buttonClass} onClick={handlerFunction}>{buttonText}</button>
}

export const StyledInputField = (props: any) => {
    const { inputClass, inputContainerClass, placeHolderText, value, setValueFunction, inputType, inputImage, onBlurFunction, readonly, errorHighlight } = props;

    return (
        <div className={"base-styled-input-field-container " + inputContainerClass + ' ' + (!!errorHighlight?'red-border':'')}>
            <div className="base-styles-input-icon-holder">
                {!!inputImage && <img src={inputImage} />}
            </div>
            <input
                className={'base-styled-input-field ' + inputClass}
                placeholder={placeHolderText}
                value={value}
                type={!!inputType ? inputType : 'text'}
                onBlur={onBlurFunction}
                onChange={(event: any) => setValueFunction(event.target.value)}
                readOnly={!!readonly}
            />
        </div>
    )
}

export const StyledTextArea = (props: any) => {
    const { inputClass, inputContainerClass, placeHolderText, value, setValueFunction, label ,labelImage, readonly, errorHighlight } = props;

    return (
        <div style={{width: '100%'}} className={inputContainerClass}>

            <div className="base-styled-input-text-area-label-holder">
                <img src={labelImage}/>
                <div className="base-styled-input-text-area-label">{label}</div>
            </div>

            <TextareaAutosize
                className={'base-styled-input-text-area ' + inputClass + ' ' + (!!errorHighlight?'red-border':'')}
                value={value}
                aria-label="minimum height"
                minRows={3}
                placeholder={placeHolderText}
                onChange={(event: any) => setValueFunction(event.target.value)}
                readOnly={!!readonly}
            />
        </div>
    )

}

export const ErrorMessageDisplay = (props: any) => {

    const {errorMessage,errorStyle} = props;

    if(!!errorMessage)  return <div className={"error-message "+errorStyle}>Error: {JSON.stringify(errorMessage)}</div>

    return (<div></div>)
}