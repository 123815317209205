import React from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import { ProposalComponent } from './pages/Proposal';
import { DisplayComponent } from './pages/Display';
import { AuthProvider } from './hooks/AuthContext';
import { InflProvider } from './hooks/InflContext';
import HomeComponent from './pages/HomeComponent';


Amplify.configure({
  Auth: {
    region: "ap-south-1",
    userPoolId: "ap-south-1_rX6xdVL8Y",
    userPoolWebClientId: "30vef5ghfg9b344agmq95rjleo"
  }
});

const AuthProposal = () => <AuthProvider><ProposalComponent /></AuthProvider>

function App() {
  
  return (
    <div className="App">
      <Router>
        <InflProvider>
          <Routes>
            <Route element={<AuthProposal />} path={'/:display_name/collaberate'} />
            <Route element={<DisplayComponent />} path={'/:display_name'} />
            <Route path='/' element={<HomeComponent/>} />
            <Route path='*' element={<Navigate to="/error" />} />
          </Routes>
        </InflProvider>
      </Router>
    </div>
  );
}

export default App;
