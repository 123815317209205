import { Checkbox, CircularProgress, FormControlLabel, TextareaAutosize } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { facebookIcon, homeIcon, instagramIcon, longtextIcon, nameIcon, phoneIcon, usernameIcon, websiteIcon, youtubeIcon } from '../../icons';
import { Proposal } from '../../interfaces';
import { ProposalSubmittedComponent } from '../ProposalSubmitted';
import { createProposal } from '../utilities/backend';
import { ErrorMessageDisplay, StyledButton, StyledInputField, StyledTextArea } from '../utilities/BaseComponents';
import { allTrueValidation, phoneValid } from '../utilities/tools';
import './styles.css'
export const ProposalComponent = () => {


    const [proposal, setProposal] = useState<Proposal>({
        name: '',
        brand: '',
        brandHandle: '',
        deliverables: '',
        phone_number: '',
        on_instagram: false,
        on_youtube: false,
        on_facebook: false,
        on_moj: false,
        whatsappChecked: false,
        emailChecked: false
    })

    //@ts-ignore
    const { display_name }: { display_name: string } = useParams();

    const [validationCheck, setValidationCheck] = useState(false);
    const allMandatoryFilled = allTrueValidation([proposal.name, proposal.brand, proposal.brandHandle, proposal.deliverables, proposal.phone_number]);
    const allValidationPass = allTrueValidation([allMandatoryFilled, phoneValid(proposal.phone_number)]);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false);

    if (submitted) return <ProposalSubmittedComponent />

    const errorMessageHandler = () => {
        if (!allMandatoryFilled) return 'All the mandatory fields needs to be filled';
        if (!phoneValid(proposal.phone_number)) return 'Phone number is not valid';
        return errorMessage;
    }

    const handleSubmit = async () => {

        setLoadingProgress(true);

        if (!allValidationPass) {
            setLoadingProgress(false);
            setValidationCheck(true);
            return;
        }

        createProposal(proposal, display_name).then(() => setSubmitted(true))
            .catch((err) => {
                setLoadingProgress(false);
                setErrorMessage(err.message)
            })
    }

    const setAttributeHandler = (event: any, attrib_name: string) => {
        setProposal((Attr) =>
            ({ ...Attr, [attrib_name]: event }))
    }

    const PlatformSelector = ({ text }: { text: 'Instagram' | 'Youtube' | 'Facebook' }) => {

        const platform_x_attr = {
            Instagram: 'on_instagram',
            Youtube: 'on_youtube',
            Facebook: 'on_facebook'
        }

        const platform_x_icon = {
            Instagram: instagramIcon,
            Youtube: youtubeIcon,
            Facebook: facebookIcon,
        }

        const handleToggle = () => {
            //@ts-ignore
            setProposal({ ...proposal, [platform_x_attr[text]]: !proposal[platform_x_attr[text]] });
        }

        return (
            <div
                onClick={handleToggle}
                //@ts-ignore
                className={proposal[platform_x_attr[text]] ? "selected-platform-button" : "unselected-platform-button"}
            >
                <img src={platform_x_icon[text]} />
            </div>)
    }

    return (
        <div className='proposal-container'>

            <div className="proposal-collab-header">
                Let's Collab
            </div>


            <StyledInputField
                inputContainerClass='proposal-input-field'
                placeHolderText='Name'
                inputImage={nameIcon}
                value={proposal.name || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'name')}
                errorHighlight={validationCheck && (!proposal.name)}
            />

            <StyledInputField
                inputContainerClass='proposal-input-field'
                placeHolderText='Brand Name'
                inputImage={homeIcon}
                value={proposal.brand || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'brand')}
                errorHighlight={validationCheck && (!proposal.brand)}
            />

            <StyledInputField
                inputContainerClass='proposal-input-field'
                placeHolderText='Website / Instagram profile'
                inputImage={websiteIcon}
                value={proposal.brandHandle || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'brandHandle')}
                errorHighlight={validationCheck && (!proposal.brandHandle)}
            />

            <StyledInputField
                inputContainerClass='proposal-input-field'
                placeHolderText='Position'
                inputImage={usernameIcon}
                value={proposal.position || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'position')}
            />

            <StyledTextArea
                inputContainerClass='proposal-input-text-area'
                value={proposal.aboutBrand}
                setValueFunction={(event: any) => setAttributeHandler(event, 'aboutBrand')}
                label='Brief / About the brand'
                labelImage={longtextIcon}
                readonly={false}
            />

            <StyledTextArea
                inputContainerClass='proposal-input-text-area'
                value={proposal.deliverables}
                setValueFunction={(event: any) => setAttributeHandler(event, 'deliverables')}
                label='Work Brief'
                labelImage={longtextIcon}
                readonly={false}
                errorHighlight={validationCheck && (!proposal.deliverables)}
            />



            <div className="proposal-label-text">Preffered Platforms for content publishing</div>
            <div className="platform-selector-container">
                <PlatformSelector text='Instagram' />
                <PlatformSelector text='Facebook' />
                <PlatformSelector text='Youtube' />
            </div>

            <StyledInputField
                inputContainerClass='proposal-input-field'
                placeHolderText='Contact Number'
                inputImage={phoneIcon}
                value={proposal.phone_number || ''}
                setValueFunction={(event: any) => setAttributeHandler(event, 'phone_number')}
                errorHighlight={validationCheck && (!proposal.phone_number || !phoneValid(proposal.phone_number))}
            />

            <div className="proposal-consent-checkbox">
                <div className="proposal-consent-checkbox-label">Allow us to update via <span style={{ color: '#128C7E' }}>Whatsapp</span></div>
                <Checkbox
                    checked={proposal.whatsappChecked}
                    onChange={(event: any) => setProposal((Attr) =>
                        ({ ...Attr, whatsappChecked: event.target.checked }))}
                    color="default"
                />
            </div>

            <div className="proposal-consent-checkbox">
                <div className="proposal-consent-checkbox-label">Allow us to give updates via Email</div>
                <Checkbox
                    checked={proposal.emailChecked}
                    onChange={(event: any) => setProposal((Attr) =>
                        ({ ...Attr, emailChecked: event.target.checked }))}
                    color="default"
                />
            </div>

            {validationCheck && (<ErrorMessageDisplay errorMessage={
                errorMessageHandler()
            } />)}

            {loadingProgress ?
                (<CircularProgress className='loading-progress-indicator' />) : (
                    <StyledButton buttonClass='proposal-submit-button' buttonText='Submit' handlerFunction={handleSubmit} />
                )
            }
        </div>
    )
}