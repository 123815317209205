import React from 'react';
import './styles.css'

export const ErrorComponent = () => {
    return (
        <div className='error-user-container'>
            <div className="error-oops">Oops</div>
            <div className="error-profile">Profile Not found</div>

            <div className="error-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}