import React, { createContext, useEffect, useState } from 'react';
import { DisplayInfo, InflContextType } from '../interfaces';
import { ErrorComponent } from '../pages/Error';
import { getProfile } from '../pages/utilities/backend';



export const InflContext = createContext<InflContextType>({ loading: true, displayInfo: {}, triggerInfo: (name: string) => null })

export const InflProvider = (props: any) => {
    const [inflDetails, setInflDetails] = useState<{ loading: boolean, displayInfo: DisplayInfo }>({ loading: true, displayInfo: {} });
    const [displayName, setDisplayName] = useState('');
    const [invalidName, setInvalidName] = useState(false)

    const triggerInfo = (name: string) => {
        if (displayName == name) return;

        getProfile(name).then(({ attribs }) => {
            if (!attribs) {
                setInvalidName(true);
            } else {
                setInflDetails({ loading: false, displayInfo: attribs });
                setInvalidName(false);
            }
        })

    }

    if (invalidName) {
        return (<ErrorComponent />)
    }

    return (
        <InflContext.Provider value={{ ...inflDetails, triggerInfo }}>{props.children}</InflContext.Provider>
    )

}