import { Auth } from 'aws-amplify';
import axios from 'axios';
import { Proposal } from '../../interfaces';

const VOLYUME_BACKEND = 'https://j3xzef70wh.execute-api.ap-south-1.amazonaws.com/prod/';

export const getProfile = async (display_name: string) => {
    console.log('getProfile')
    const data = await axios.get(VOLYUME_BACKEND+'link/'+display_name);
    console.log(data)
    return data.data;
};

export const createProposal = async (proposal: Proposal,user_link: string) => {

    const session = await Auth.currentSession();
    const config = {
		headers: { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` }
	};

    await axios.post(VOLYUME_BACKEND+'proposal',{proposal,user_link},config);
}