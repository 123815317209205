import { CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react'
import { emailIcon } from '../../icons';
import { ErrorMessageDisplay, StyledButton, StyledInputField } from '../utilities/BaseComponents';
import { emailValid } from '../utilities/tools';
import './styles.css';

export const LoginComponent = () => {

    const [email, setEmail] = useState('');
    const [cognitoUser, setCognitoUser] = useState<any>();
    const [otpSent, setOtpSent] = useState(false);
    const [otpString, setOtpString] = React.useState('');
    const [validationCheck, setValidationCheck] = useState(false);
    const [errorComments, setErrorComments] = useState('');

    const [loadingProgress, setLoadingProgress] = useState(false);

    const errorMessageHandler = () => {
        if (!emailValid(email)) return 'Email is not valid';
        if (!otpString) return 'Enter the OTP'
        return (errorComments);
    }

    const onEmailInput = () => {

        setLoadingProgress(true);

        if (!emailValid(email)) {
            setValidationCheck(true);
            setLoadingProgress(false);
            return;
        }

        setErrorComments('');
        setOtpSent(true);
        setValidationCheck(false);
        signUp()
    }

    async function signUp() {

        const params = {
            username: String(email),
            password: 'kjfadskl',
        };
        Auth.signUp(params).then(() => signIn())
            .catch((error) => {
                console.log(error)
                signIn()
            })
    }

    async function signIn() {
        setLoadingProgress(false);
        let User = await Auth.signIn(email);

        setCognitoUser(User);
        Auth.currentAuthenticatedUser().catch((error) => {
            console.log(error);
            Auth.signOut();
        })
    };

    async function answerCustomChallenge() {

        setLoadingProgress(true);
        setErrorComments('');

        if (!otpString) {
            setValidationCheck(true);
            setLoadingProgress(false);
            return;
        }

        Auth.sendCustomChallengeAnswer(cognitoUser, String(otpString))
            .then((data) => {
                console.log(data)
                if (!data.attributes) {
                    setErrorComments('Wrong OTP. Recheck the OTP')
                }
                setLoadingProgress(false);
            })
            .catch((err) => {
                if (err.name == 'NotAuthorizedException') {
                    setErrorComments('All retries failed. Try later')
                } else {
                    setErrorComments(err.message);
                }
                setLoadingProgress(false);
            });
    }


    return (
        <div className='login-container'>
            {
                !otpSent ? (
                    <>
                        <div className="login-input-label">Verification</div>
                        <StyledInputField inputImage={emailIcon} inputContainerClass='login-input-field' placeHolderText='Email' value={email} setValueFunction={setEmail} errorHighlight={validationCheck && (!email || !emailValid(email))} />
                        {validationCheck && <ErrorMessageDisplay errorMessage={errorMessageHandler()} />}
                        {loadingProgress ?
                            (<CircularProgress className='loading-progress-indicator' />) : (
                                <StyledButton buttonText='Submit' buttonClass='styled-button-profile-save' handlerFunction={onEmailInput} />
                            )
                        }
                    </>
                ) : (
                    <>
                        <div className="login-input-label">Check your inbox</div>
                        <StyledInputField inputType='number' inputContainerClass='login-input-field' placeHolderText='Enter the OTP' value={otpString} setValueFunction={setOtpString} errorHighlight={validationCheck && !otpString} />
                        {validationCheck && <ErrorMessageDisplay errorMessage={errorMessageHandler()} />}
                        {loadingProgress ?
                            (<CircularProgress className='loading-progress-indicator' />) : (
                                <StyledButton buttonText='Submit' buttonClass='styled-button-profile-save' handlerFunction={answerCustomChallenge} />
                            )
                        }
                    </>
                )
            }

            <div className="login-display-subtext">Powered by Watevr</div>

        </div>
    )
}