
export const allTrueValidation = (states:any[]) =>{

    let valid = true;
    states.forEach((state)=> {
        valid = valid && (!!state);
    })

    return valid;
}

export const emailValid = (email:string) => {

    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    return email.match(emailRegex);
}

export const phoneValid = (phone: any) => {

    if((Number(phone)>1000000000) && (Number(phone)<9999999999)) return true;

    return false;

}

export const urlPadder = (url: string) => {
    if(url.startsWith('https:') || url.startsWith('http:')) return (url);

    return ('https://'+url);
}