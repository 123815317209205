import React from 'react';
import './styles.css'

export const ProposalSubmittedComponent = () => {
    return (
        <div className='proposal-submitted-user-container'>
            <div className="proposal-submitted-oops">Thank you</div>
            <div className="proposal-submitted-profile">Will be notified soon</div>

            <div className="proposal-submitted-link-display-subtext">Powered by Watevr</div>
        </div>
    )
}