import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InflContext } from '../../hooks/InflContext';
import { verifiedIcon } from '../../icons';
import { DisplayInfo } from '../../interfaces';
import { LoadingDisplay } from '../LoadingDisplay';
import { getProfile } from '../utilities/backend';
import { instagramIcon, youtubeIcon, facebookIcon, justCollabIcon } from '../../icons'
import './styles.css';
import { urlPadder } from '../utilities/tools';


export const DisplayComponent = () => {

    const { loading, displayInfo, triggerInfo } = useContext(InflContext);

    const navigate = useNavigate();

    //@ts-ignore
    const { display_name }: { display_name: string } = useParams();



    const collaberateHandler = () => {
        navigate(`/${display_name}/collaberate`);
    };

    const handleCreateAccount = () => {
        window.open('https://login.justcollab.in', "_blank")
    }

    if (loading) {
        triggerInfo(display_name);
        return (<LoadingDisplay processMessage='fetching details' />)
    };

    return (
        <div className='display-container'>
            <div className="width-spacer"></div>
            <div className="display-details-card">
                <img src={
                    //@ts-ignore
                    displayInfo.image + '?d=' + Date.now()
                } className='display-image' />

                <div className="display-influencer-name">
                    {displayInfo['custom:link_display']} <img src={verifiedIcon} className='verified-icon' />
                </div>

                <div className="platform-links-container">
                    {!!displayInfo['custom:instagram_link'] && (
                        <a href={urlPadder(displayInfo['custom:instagram_link'])} target='_blank' className="platform-link">
                            <img src={instagramIcon} />
                        </a>
                    )}

                    {!!displayInfo['custom:youtube_link'] &&
                        <a href={urlPadder(displayInfo['custom:youtube_link'])} target='_blank' className="platform-link">
                            <img src={youtubeIcon} />
                        </a>
                    }

                    {!!displayInfo['custom:facebook_link'] &&
                        <a href={urlPadder(displayInfo['custom:facebook_link'])} target='_blank' className="platform-link">
                            <img src={facebookIcon} />
                        </a>
                    }
                </div>

                <button onClick={collaberateHandler} className="collaberate-button">
                    Let’s Collab
                </button>

                {
                    !!displayInfo['custom:extra_label1'] && !!displayInfo['custom:extra_link1'] && (
                        <a href={urlPadder(displayInfo['custom:extra_link1'])} target='_blank' className="custom-link-button">
                            {displayInfo['custom:extra_label1']}
                        </a>
                    )
                }

                {
                    !!displayInfo['custom:extra_label2'] && !!displayInfo['custom:extra_link2'] && (
                        <a href={urlPadder(displayInfo['custom:extra_link2'])} target='_blank' className="custom-link-button">
                            {displayInfo['custom:extra_label2']}
                        </a>
                    )
                }

                {
                    !!displayInfo['custom:extra_label3'] && !!displayInfo['custom:extra_link3'] && (
                        <a href={urlPadder(displayInfo['custom:extra_link3'])} target='_blank' className="custom-link-button">
                            {displayInfo['custom:extra_label3']}
                        </a>
                    )
                }
            </div>

            <div className="flex-spacer"></div>

            <div className="bottom-floater-set">
                <div onClick={handleCreateAccount} className='create-account-button'>Create Account</div>

                <img className='just-collab-icon' src={justCollabIcon} />
                <div className="link-display-subtext">Powered by Watevr</div>
            </div>


        </div>
    )
}