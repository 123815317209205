import { Auth, Hub } from 'aws-amplify';
import React, { createContext, useEffect, useState } from 'react';
import { LoginStatus } from '../interfaces';
import { LoadingDisplay } from '../pages/LoadingDisplay';
import { LoginComponent } from '../pages/LoginComponent';


export const AuthContext = createContext({ loginStatus: { loading: true, isLoggedIn: false } });

export const AuthProvider = (props: any) => {
    const [loginStatus, setLoginStatus] = useState<LoginStatus>({ loading: true, isLoggedIn: false });

    const getToken = () => {
        Auth.currentSession().then((session) => {
            setLoginStatus({ loading: false, isLoggedIn: true, token: session.getIdToken().getJwtToken() })
        })
            .catch(() => setLoginStatus({ loading: false, isLoggedIn: false }))
    }

    Hub.listen("auth", (data) => {
        switch (data.payload.event) {
            case "signIn":
                console.log("user signed in");
                getToken();
                break;

            case "signOut":
                console.log("user signed in");
                setLoginStatus({ loading: false, isLoggedIn: false });
                break;
        }
    });

    useEffect(() => {
        getToken();
    }, [])

    if (loginStatus.loading) return (<LoadingDisplay processMessage='Authenticating'/>);

    if (!loginStatus.isLoggedIn) return (<LoginComponent />)

    return (
        <AuthContext.Provider value={{ loginStatus }}>{props.children}</AuthContext.Provider>
    );
}